import * as React from 'react'
import * as Yup from 'yup'
import loadable from '@loadable/component'

const FeatureRadio = loadable(() => import('/src/components/Form/FeatureRadio'))
const FieldSet = loadable(() => import('/src/components/Form/FieldSet'))
const FieldSpacer = loadable(() => import('/src/components/Form/FieldSpacer'))
const WizardStep = loadable(() =>
    import('/src/components/Form/FormWizard/WizardStep')
)

export const validationSchemaStepProtect = Yup.object({
    protect: Yup.string()
})

const StepProtect = ({ userName }) => {
    return (
        <WizardStep
            title={`Thanks${userName && ` ${userName}`}, let's get you sorted`}
            requiredText={true}
        >
            <FieldSet
                align={'centered'}
                legend={'What do you want to protect?'}
                groupName={'protect'}
                required
            >
                <FieldSpacer>
                    <FeatureRadio
                        label={'My family'}
                        groupName={'protect'}
                        name={'protectFamily'}
                        value={'family'}
                        icon={'family'}
                    />
                </FieldSpacer>

                <FieldSpacer>
                    <FeatureRadio
                        label={'My home'}
                        groupName={'protect'}
                        name={'protectHome'}
                        value={'home'}
                        icon={'home'}
                    />
                </FieldSpacer>

                <FieldSpacer>
                    <FeatureRadio
                        label={"I'm not sure"}
                        groupName={'protect'}
                        name={'protectUnsure'}
                        value={'unsure'}
                        icon={'unsure'}
                    />
                </FieldSpacer>
            </FieldSet>
        </WizardStep>
    )
}

export default StepProtect
